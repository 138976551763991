import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Container/ContentContainer"
import breakpoints from "../../styles/breakpoints"
import { ButtonStyleOrangeDark } from "../Core/Buttons/Styles"

const StyledForm = styled.form`
  max-width: 672px;
  margin: 97px auto 84px;

  @media (min-width: ${breakpoints.md}) {
    margin: 54px auto 144px;
  }
`

const FormField = styled.div`
  position: relative;
  border-bottom: ${(props) =>
    props.textarea ? "none" : `1px solid ${colors.grey}`};
  margin-bottom: ${(props) => (props.last ? "0" : "64px")};
  height: ${(props) => props.textarea && "193px"};

  input,
  textarea,
  .placeholder {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    ${fonts.labGrotesqueLight};
    color: ${colors.greyDarkest};
    font-size: 18px;
    line-height: 1.6;
  }

  textarea {
    height: 100%;
    padding: 15px 20px;
    border: 1px solid ${colors.grey};
    resize: none;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: ${(props) => (props.textarea ? "15px 20px" : "0")};
    span {
      color: ${colors.orangeDark};
    }
  }

  .placeholder {
    display: ${(props) => props.active && "none"};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${(props) => (props.textarea ? "71px" : "0")};
    textarea {
      padding: 23px;
    }
    .placeholder {
      padding: ${(props) => (props.textarea ? "23px" : "0")};
    }
  }
`

const Submit = styled.button`
  ${ButtonStyleOrangeDark};
  width: 100%;
  margin-top: 34px;
`

const HoneyPot = styled.div`
  display: none;
`

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [formSubmit, setFormSubmit] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    let contactForm = document.getElementById("contact-form")
    let formData = new FormData(contactForm)

    fetch(contactForm.getAttribute("action"), {
      method: "POST",
      body: formData,
    })
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          setFormSubmit(true)
        } else {
          setFormSubmit(false)
        }
      })
      .finally(() => {
        setTimeout(() => {
          setFormSubmit(null)
        }, 1500);
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <ContentContainer>
      <StyledForm
        method="POST"
        name="contact"
        data-netlify="true"
        id="contact-form"
        data-netlify-honeypot="bag"
        onSubmit={handleSubmit}
      >
        <FormField active={name !== ""}>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            id="name"
            value={name}
            name="name"
            aria-label="Name"
            required
          />
          <div className="placeholder">
            Your name <span>*</span>
          </div>
        </FormField>
        <FormField active={email !== ""}>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            value={email}
            name="email"
            aria-label="Email"
            required
          />
          <div className="placeholder">
            Email address <span>*</span>
          </div>
        </FormField>
        <FormField active={zipCode !== ""}>
          <input
            type="text"
            onChange={(e) => setZipCode(e.target.value)}
            id="zipcode"
            name="zipcode"
            value={zipCode}
            aria-label="Zipcode"
            required
          />
          <div className="placeholder">
            Zipcode <span>*</span>
          </div>
        </FormField>
        <FormField active={phone !== ""}>
          <input
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            id="phone"
            name="phone"
            value={phone}
            aria-label="Phone"
          />
          <div className="placeholder">Phone number</div>
        </FormField>
        <FormField active={subject !== ""}>
          <input
            type="text"
            onChange={(e) => setSubject(e.target.value)}
            id="subject"
            name="subject"
            value={subject}
            aria-label="Subject"
            required
          />
          <div className="placeholder">
            Subject <span>*</span>
          </div>
        </FormField>
        <FormField textarea last active={message !== ""}>
          <textarea
            type="textarea"
            onChange={(e) => setMessage(e.target.value)}
            id="message"
            name="message"
            value={message}
            aria-label="Message"
            required
          />
          <div className="placeholder">
            Message <span>*</span>
          </div>
        </FormField>
        <HoneyPot>
          <input name="bag" aria-label="contact" />
        </HoneyPot>
        <input type="hidden" name="form-name" value="contact" aria-label="contact"/>
        <Submit type="submit">{formSubmit === null ? "Send your message" : formSubmit ? "Submitted!" : "Please try again!"}</Submit>
      </StyledForm>
    </ContentContainer>
  )
}

export default ContactForm
