import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Contact/PageContents"

const ContactPage = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page } = data
  const { metaTitle, metaDescription } = page

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <PageContents blocks={page.blocks}/>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    page: contentfulPage(slug: { eq: "contact" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulIntroBlock {
          id
          header
          subHeader {
            raw
          }
          references {
            ... on ContentfulTextBlock {
              content {
                raw
              }
            }
          }
        }
        ... on ContentfulLineDivider {
          id
          points {
            point
          }
        }
        ... on ContentfulInstagram {
          id
          name
          image {
            gatsbyImageData(width: 636, aspectRatio: 1)
          }
          username
          link
          profileImage {
            gatsbyImageData(width: 102, aspectRatio: 1)
          }
        }
      }
    }
  }
`
