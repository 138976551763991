import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../styles/breakpoints"

const IntroBlockContainer = styled.div`
  max-width: 672px;
  margin: auto;
`

const Intro = styled.div`
  margin: 95px auto 106px;
  text-align: center;

  h1 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 1;
    color: ${colors.grey};
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 167px auto 132px;

    h1 {
      font-size: 72px;
    }
  }
`

const IntroContent = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 1.6;
  margin-top: 26px;
  color: ${colors.grey};

  h2 {
    margin-bottom: 28px;
  }

  a {
    color: inherit;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;

    h2 {
      ${fonts.labGrotesqueBold};
      letter-spacing: 3.8px;
      margin-bottom: 0;
      font-size: 19px;
      line-height: normal;
    }
    p {
      b {
        ${fonts.labGrotesqueBold};
      }
    }
  }
`

const Content = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 1.6;
  text-align: center;

  a {
    color: inherit;
  }

  p {
    margin-bottom: 28px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    color: ${colors.black};
  }
`

const IntroBlock = (props) => {
  const { header, subHeader, references } = props
  return (
    <ContentContainer>
      <IntroBlockContainer>
        <Intro>
          <h1>{header}</h1>
          <IntroContent>
            {renderRichText(subHeader)}
          </IntroContent>
        </Intro>
        {references.map((reference, index) => {
          const { __typename, content } = reference
          const isText = __typename === "ContentfulTextBlock"
          return isText ? (
            <Content key={index}>{renderRichText(content)}</Content>
          ) : null
        })}
      </IntroBlockContainer>
    </ContentContainer>
  )
}

export default IntroBlock
