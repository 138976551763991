import React from "react"
import styled from "styled-components"
import IntroBlock from "./IntroBlock"
import LineDivider from "../Core/LineDivider"
import ContactForm from "./ContactForm"
import BrandPattern from "../../resources/img/patterns/brand-pattern-03.svg"
import breakpoints from "../../styles/breakpoints"
import Instagram from "../Home/Instagram"

const ContactPageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const Pattern = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: -20%;
  opacity: 0.6;
  z-index: -1;
  img {
    max-width: 150vw;
  }
  @media (min-width: ${breakpoints.md}) {
    left: 0;
    img {
      max-width: 972px;
    }
  }
`

const PageContents = (props) => {
  const { blocks } = props
  const pageBlocks = []
  const instagram = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block

    if (type === "ContentfulIntroBlock") {
      pageBlocks.push(
        <React.Fragment key={index}>
          <IntroBlock {...block} />
          <ContactForm />
        </React.Fragment>
      )
    }

    if (type === "ContentfulLineDivider") {
      pageBlocks.push(<LineDivider {...block} key={index} />)
    }

    if (type === "ContentfulInstagram") {
      instagram.push(block)
    }
    if (instagram.length !== 0 && index === blocks.map(el => el.__typename).lastIndexOf("ContentfulInstagram")) {
      pageBlocks.push(<Instagram posts={instagram} key={index}/>)
    }
  })

  return (
    <ContactPageContainer>
      {pageBlocks}
      <Pattern>
        <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
      </Pattern>
    </ContactPageContainer>
  )
}

export default PageContents
